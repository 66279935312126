<template>
    <div>
        <center>
            <h3>Import Dataset</h3>
        </center>
        <br>
        <form v-on:submit.prevent="addMembers">
            <label for="">File CSV*</label>
            <input type="file" class="form-control" name="file" @change="readFile" accept=".csv" required />
            <br />
            <p>
                File contoh dapat diunduh
                <a style="display: contents" href="/excel/contohdatasetcsv.csv" download>
                    disini
                </a>

            </p>
            <br />
            <button v-if="csvParseLoading"
                class="btn btn-spinner btn-primary btn-block btn-default btn-squared text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                disabled>
                <div class="spinner-border spinner" role="status"></div>
                Sedang memproses data csv
            </button>
            <button v-else-if="!csvImportReady && !csvParseLoading" class="btn btn-danger btn-block" disabled>
                Silahkan Upload CSV Terlebih Dahulu
            </button>
            <button v-else-if="csvImportReady && !csvParseLoading" type="submit" class="btn btn-primary btn-block">
                Upload
            </button>
        </form>
    </div>
</template>

<script>

    export default {
        components: {
        },
        props: ["id"],
        data() {
            return {
                importData: [],
                form: {
                    name: "",
                    number: "",
                    variable: [],
                    device: null,
                },
                null_value: null,
                csvParseLoading: false,
                csvImportReady: false,
                finalData: []
            };
        },
        mounted() {

        },
        methods: {
            async readFile() {
                let file = event.target.files[0];
                if (file.type != "text/csv") {
                    this.$toast.error("File harus .csv")
                    return
                }

                this.importData = []
                this.csvImportReady = false
                this.csvParseLoading = true

                try {
                    let result = await this.papaParseAsync(file);

                    result.shift()

                    for (let i = 0; i < result.length; i++) {
                        const element = result[i];

                        if(element?.[0] == '' || element?.[1] == '') {
                            continue
                        }
                        
                        // let memberData = []
                        // for (let j = 1; j < element.length - 1; j++) {
                        //     memberData.push(element[j])
                        // }
                        this.finalData.push({
                            "q": element[0],
                            "a": element[1],
                        })  
                    }

                    if (this.finalData.length != 0) this.csvImportReady = true
                } catch (err) {
                    this.$toast.error(err);
                }
                this.csvParseLoading = false
            },
            arrayToData(array) {
                for (let i = 1; i < array.length; i++) {
                    this.importData.push({
                        request: array[i][0],
                        text: array[i][1],
                        file: array[i][2],
                        data: array[i][3],
                        message_type: array[i][4],
                        hold: parseInt(array[i][5]),
                        sensitive: parseInt(array[i][6]),
                    })
                }
            },
            papaParseAsync(file) {
                return new Promise((resolve, reject) => {
                    this.$papa.parse(file, {
                        header: false,
                        complete(results) {
                            resolve(results.data)
                        },
                        error(err, ) {
                            reject(err)
                        }
                    })
                })
            },
            async addMembers() {
                this.csvParseLoading = true
                try {
                    let create_dataset = await this.$store.dispatch('chatgpt_dataset_create', { id: this.id, data: this.finalData } )

                    this.$toast.success(create_dataset.data.message);
                    this.$root.$emit("toggle_modal", "import");
                    this.$root.$emit("refresh_table", true);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.csvParseLoading = false
            },
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn-spinner {
        line-height: 1rem !important;
    }
</style>