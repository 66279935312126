<template>
    <div>
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :totalRows="table.total_records"
        :isLoading.sync="table.isLoading"
        :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }"
        :columns="table.columns"
        :rows="table.rows"
      >
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'token_limit'">
                Per Chat : {{ props.row.max_token_chat }}
                <br>
                Per Number / Day : {{ props.row.daily_max_token_number }}
                <br>
                Per Day : {{ props.row.daily_max_token_global }}
            </span>
            <span v-if="props.column.field == 'device'">
                {{ getDeviceNameByID(props.row.device_id) }}
            </span>
            <span v-if="props.column.field == 'is_trained'">
                <span class="badge badge-success" v-if="props.column.trained_prompt != null">Sudah di Training</span>
                <span class="badge badge-danger" v-else>Belum di Training</span>

            </span>
          <span v-if="props.column.field == 'action'">
            <!-- <a v-on:click="editDataset(props.row.id)">
              <a class="btn btn-primary text-white margin5">Detail</a>
            </a> -->
            <a
              v-on:click="deleteChatGPTHistory(props.row.id)"
              class="btn btn-danger text-white margin5"
            >
              Hapus
            </a>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>

  export default {
    props: ['id'],
    data() {
      return {
        table: {
          total_records: 100,
          isLoading: true,
          columns: [
            {
              label: "Nomor",
              field: "number",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "20%",
            },
            {
              label: "Pesan",
              field: "question",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "25%",
            },
            {
              label: "Jawaban",
              field: "answer",
              filterOptions: {
                enabled: true,
              },
              sortable: false,
              width: "25%",
            },
            {
              label: "Token",
              field: "total_token",
              filterOptions: {
                enabled: false,
              },
              sortable: false,
              width: "10%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [
              {
                field: "",
                type: "",
              },
            ],
            page: 1,
            limit: 10,
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      async deleteChatGPTHistory(id) {
        try {
          let response = await this.$store.dispatch("chatgpt_delete_history", {id: this.id, history_id: id});
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign(
          {},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({limit: params.currentPerPage});
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [
            {
              type: params.sortType,
              field: this.table.columns[params.columnIndex].field,
            },
          ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.chatgpt_history;

        //   for (let i = 0; i < this.table.rows.length; i++) {
        //     this.table.rows[i].count_device = JSON.parse(this.table.rows[i].device).length ?? 0
        //   }
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        param.chatgpt_id = this.id
        return await this.$store.dispatch("chatgpt_history_list", param);
      },
      async editDataset(id) {
        this.$root.$emit("toggle_modal", "edit_dataset")
        this.$root.$emit("edit_dataset", id)
      },
    },
  };
  </script>
  
  <style scoped>
  .margin5 {
    margin: 5px;
  }
  </style>
  