<template>
    <div>
        <center>
            <h4>Tambah Dataset</h4>
        </center>
        <br>
        <form v-on:submit.prevent="addDataset" enctype="multipart/form-data">
            <label> Contoh Pertanyaan / Question </label>
            <textarea v-model="form.q" rows="5" class="form-control"></textarea>
            <br>
            <label> Jawaban / Answer </label>
            <textarea v-model="form.a" rows="5" class="form-control"></textarea>
            <br>
            <!-- <label for="">Pesan Registrasi</label>
            <CustomTextArea v-model="form.message"></CustomTextArea>
            <i>Anda bisa memanggil variabel menggunakan [] (Bracket)</i>
            <br>
            <i>Variabel Tersedia : {{ available_variable }}</i>
            <br> -->
            <br>
            <button class="btn btn-success btn-block">Simpan</button>
        </form>
    </div>
</template>

<script>

    export default {
        props: ['id', "dataset_id"],
        components: {
        },
        data() {
            return {
                form: {
                    q: "",
                    a: "",
                },
            };
        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }

            this.$root.$on("toggle_modal", this.fnToggleModal);

            if(this.dataset_id){
                this.getDataset()
            }
        },
        methods: {
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            onFileChange(e) {
                this.form.avatar = e.target.files[0]
            },
            async getDataset(){
                let res = await this.$store.dispatch("chatgpt_dataset_get", {id: this.id, dataset_id: this.dataset_id});
                let r = res.data.data.chatgpt

                this.form.q = r.q
                this.form.a = r.a
            },
            async addDataset(){
                try{
                    let create_dataset 
                    if(this.dataset_id){
                        create_dataset = await this.$store.dispatch('chatgpt_dataset_update', { id: this.id, dataset_id: this.dataset_id, q: this.form.q, a: this.form.a } )
                        this.$root.$emit("toggle_modal", "edit_dataset")
                    }else{
                        create_dataset = await this.$store.dispatch('chatgpt_dataset_create', { id: this.id, data: [{q: this.form.q, a: this.form.a}] } )
                        this.$root.$emit("toggle_modal", "add_dataset")
                    }
                    this.$toast.success(create_dataset.data.message)
                    this.$root.$emit("refresh_table", true)
                }catch(err){
                    this.$toast.error(err.response.data.message);
                }
            }
        },
    };
</script>

<style scoped>
    

    table {
        border: 1px solid;
    }
</style>