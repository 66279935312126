<template>
    <div>
      <div style="text-align: center">
        <h3>Tambah Integrasi Chat GPT</h3>
      </div>
      <br />
      <form v-on:submit.prevent="addChatGPT">
        <div class="row">
            <div class="col-md-12">
                <label>Nama Bot</label>
                <input type="text" class="form-control" v-model="form.name" autofocus required />
                <br />
                <label> Device </label>
                    <multiselect v-model="form.device" tag-placeholder="Pilih device"
                        placeholder="Pilih device" label="name" track-by="id" :options="device"
                        :multiple="false"></multiselect>
                <br>
                <label for="">OpenAI API KEY</label>
                <textarea v-model="form.api_key" class="form-control"  rows="5"></textarea>
                <br>
                <label>AI Model</label>
                <select v-model="form.models" class="form-control" selected>
                    <option value="gpt-4o-mini" selected>gpt-4o-mini (Termurah)</option>
                    <option value="gpt-4o">gpt-4o</option>
                    <option value="gpt-4">gpt-4</option>
                    <option value="gpt-4-turbo">gpt-4o-turbo</option>
                </select>
            </div>
            <div class="col-md-6" style="margin-top:10px">
                <label>Max Token / Chat</label>
                <input type="number" placeholder="contoh : 128" class="form-control" v-model="form.max_token_chat" autofocus required />
                <br />
                <label>Max Token / Nomor</label>
                <input type="number" placeholder="contoh : 1024" class="form-control" v-model="form.daily_max_token_number" autofocus required />
                <br />
                <label>Max Token / Hari</label>
                <input type="number" placeholder="contoh : 100000" class="form-control" v-model="form.daily_max_token_global" autofocus required />
                <br />
                <label>Temperature (0.1 - 1)</label>
                <input type="number" placeholder="0.5" min="0" max="1" step="0.1" class="form-control" v-model="form.temperature" autofocus required />
                <i>*Makin tinggi, jawaban makin bervariasi</i>
                <br>
                <br />
                <label>Top P (0.1 - 1)</label>
                <input type="number" placeholder="0.5" min="0" max="1" step="0.1" class="form-control" v-model="form.top_p" autofocus required />
                <i>*Makin tinggi, jawaban makin panjang</i>
            </div>
            <div class="col-md-6"  style="margin-top:10px">
                <label for="">Fallback Message</label>
                <textarea v-model="form.fallback_message" class="form-control"></textarea>
                <i>*Pesan ketika chat gpt gagal merespon</i>
                <br>
                <br>
                <label for="">Reenable Ai Duration (menit)</label>
                <input type="number" placeholder="" class="form-control" v-model="form.reenable_ai_duration" autofocus required />
                <i>*Waktu percakapan kembali di Respon AI, setelah user chat dengan cs asli</i>
                <br>
                <br>
                <label for="">Custom Prompt (opsional)</label>
                <textarea v-model="form.custom_prompt" class="form-control" placeholder="Kamu adalah customer service produk A"></textarea>
                <br>
                <!-- <input name="hide" class="checkbox" type="checkbox" id="check-1" v-model="disable_during_office_hour">
                <label for="check-1">
                    <span class="checkbox-text" style="margin-left: 5px;">Disable During Office Hour</span>
                </label>
                <br> -->
                <input name="hide" class="checkbox" type="checkbox" id="check-2" v-mode="enable_memory">
                <label for="check-2">
                    <span class="checkbox-text" style="margin-left: 5px;">Enable Memory</span>
                </label>
                <br>
                <i>*ChatGPT akan mengingat percakapan 24 jam terakhir (billing lebih mahal)</i>
                <br>
            </div>
        </div>
        <br>
        <div class="button-group d-flex pt-25">
            <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
              Tambah
            </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
      import Multiselect from 'vue-multiselect'

  export default {
    props: ['device'],
    components: {
            Multiselect,
        },
    data() {
      return {
        form: {
            device: [],
            name: "",
            api_key: "",
            models: "gpt-4o-mini",
            enable_memory: false,
            max_token_chat: 128,
            daily_max_token_number: 1024,
            daily_max_token_global: 102400,
            temperature: 0.5,
            top_p: 0.5,
            disable_during_office_hour: false,
            fallback_message: "",
            reenable_ai_duration: 15,
            custom_prompt: "",
        },
      };
    },
    mounted() {
  
    },
    methods: {
      async addChatGPT(){
        try{
            let request = this.form
            console.log(request)
            //   request.multidevice = parseInt(request.multidevice)
            request.device_id = request.device.id
            request.enable_memory = request.enable_memory ? 1 : 0
            request.disable_during_office_hour = request.disable_during_office_hour ? 1 : 0
            request.max_token_chat = parseInt(request.max_token_chat)
            request.daily_max_token_number = parseInt(request.daily_max_token_number)
            request.daily_max_token_global = parseInt(request.daily_max_token_global)
            request.reenable_ai_duration = parseInt(request.reenable_ai_duration)
            request.temperature = parseFloat(request.temperature)
            request.top_p = parseFloat(request.top_p)
            let response = await this.$store.dispatch('chatgpt_create', request)
            
            this.$toast.success(response.data.message);
            this.$root.$emit("toggle_modal", "new_bot")
            this.$root.$emit("refresh_table", true)
        }catch(err){
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
  </script>
  
