<template>
    <div class="contents">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="breadcrumb-main user-member justify-content-sm-between ">
                        <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                            <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                <h4 class="text-capitalize fw-500 breadcrumb-title">Detail ChatGPT</h4>
                            </div>
                        </div>
                        <div class="action-btn">
                            <!-- <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Anggota
                            </a> -->
                            <a  v-on:click="toggleModal('import')" class="btn px-15 btn-primary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-upload fs-16"></i>
                                Import Dataset
                            </a>
                            <a v-on:click="trainDataset()" class="btn px-15 btn-secondary text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-robot fs-16"></i>
                                Training Dataset
                            </a>
                            <download-csv ref="exportMember" :data="campaignMembers" :name="campaignName"
                                style="display: none"></download-csv>
                            <!-- <a v-on:click="toggleModal('new_campaign')" class="btn px-15 btn-success text-white"
                                style="display: inline-flex; margin: 5px">
                                <i class="fas fa-message fs-16"></i>
                                Broadcast
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                        <div class="tab-wrapper">
                            <div class="atbd-tab tab-horizontal">
                                <ul class="nav nav-tabs vertical-tabs" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.one }"
                                            v-on:click="changePage('one')">Detail</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.two }"
                                            v-on:click="changePage('two')">Dataset</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" :class="{ active: page.three }"
                                            v-on:click="changePage('three')">History Message</a>
                                    </li>
                                </ul>
                                <div class="tab-content" v-if="!isLoading">
                                    <div class="tab-pane fade" :class="{ 'show active': page.one }" id="tab-v-1"
                                        role="tabpanel" aria-labelledby="tab-v-1-tab">
                                        <Edit :device="device" :id="edit_id" :form="chatgpt" />
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.two }" id="tab-v-2"
                                        role="tabpanel" aria-labelledby="tab-v-2-tab">
                                        <button v-on:click="toggleModal('add_dataset')" class="btn btn-success">Tambah Dataset</button>
                                        <br>
                                        <br>
                                        <ChatGPTDataset :id="edit_id" />
                                    </div>
                                    <div class="tab-pane fade" :class="{ 'show active': page.three }" id="tab-v-2"
                                        role="tabpanel" aria-labelledby="tab-v-2-tab">
                                        <ChatGPTHistory :id="edit_id" />
                                    </div>
                                </div>
                                <div class="tab-content" v-else>
                                    <center>
                                        <div class="spinner-border spinner" role="status"></div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <modal name="add_dataset" width="70%" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDataset :id="edit_id"></AddDataset>
                </div>
            </modal>
            <modal name="edit_dataset" width="70%" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddDataset :id="edit_id" :dataset_id="dataset_id"></AddDataset>
                </div>
            </modal>
            <modal name="import" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <ImportDataset :id="edit_id" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
    import Edit from "@/components/forms/chat_gpt/Edit.vue";
    import ChatGPTDataset from "@/components/tables/ChatGPTDataset.vue";
    import ChatGPTHistory from "@/components/tables/ChatGPTHistory.vue";
    import AddDataset from "@/components/forms/chat_gpt/AddDataset.vue";
    import ImportDataset from "@/components/forms/chat_gpt/ImportDataset.vue";

    //import SendBlast from "@/components/forms/outgoing/SendBlast.vue";
    //import moment from 'moment'

    export default {
        components: {
            Edit,
            ChatGPTDataset,
            AddDataset,
            ChatGPTHistory,
            ImportDataset
            //SendBlast
        },
        data() {
            return {
                edit_id: this.$route.query.id,
                page: {
                    one: true,
                    two: false,
                    three: false,
                    four: false,
                    five: false,
                    six: false, 
                    seven: false
                },
                isLoading: true,
                device: [],
                chatgpt: {},
                campaignMembers: [],
                campaignName: "",
                dataset_id: ""
            };
        },
        async beforeMount() {

        },
        beforeDestroy(){
            this.$root.$off("toggle_modal", this.fnToggleModal);
            this.$root.$off("edit_dataset", this.fnSetDatasetID);
        },
        mounted() {
            this.fnToggleModal = (param) => {
                this.toggleModal(param);
            }

            this.fnSetDatasetID = (param) => {
                this.dataset_id = param;
                // this.toggleModal("edit_dataset");
            }
            this.$root.$on("toggle_modal", this.fnToggleModal);
            this.$root.$on("edit_dataset", this.fnSetDatasetID);

            this.getAllData()
        },
        methods: {
            async getAllData() {
                await this.getDevice({
                    status: "all"
                }).then(data => {
                    this.device = data
                })
                await this.getChatGptDetail({
                    id: this.edit_id
                }).then(data => {
                    this.chatgpt = data
                })
                this.isLoading = false
            },
            toggleModal(param) {
                this.$modal.toggle(param);
            },
            async getDevice(param) {
                let res = await this.$store.dispatch("device_list_all", param);
                return res.data.data.devices
            },
            async getChatGptDetail(param) {
                let res = await this.$store.dispatch("chatgpt_detail", param);
                return res.data.data.chatgpt
            },
            changePage(page) {
                this.page.one = false
                this.page.two = false
                this.page.three = false
                this.page.four = false
                this.page.five = false
                this.page.six = false
                this.page.seven = false

                if (page == "one") this.page.one = true
                if (page == "two") this.page.two = true
                if (page == "three") this.page.three = true
                if (page == "four") this.page.four = true
                if (page == "five") this.page.five = true
                if (page == "six") this.page.six = true
                if (page == "seven") this.page.seven = true
            },
            async trainDataset(){
                let res = await this.$store.dispatch("chatgpt_train", {id: this.edit_id});
                this.$toast.success(res.data.message)
            },
            formatNumber(number){
                if(number.charAt(0) == "0"){
                    number = "62" + number.substring(1)
                }
                return number
            },
            parseVar(inputString) {
                let parts = inputString.split('#');
                parts.shift();

                let nonEmptyParts = parts.filter(part => part.trim() !== '');

                let capitalizedParts = nonEmptyParts.map(part => {
                    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
                });

                return capitalizedParts;
            },
            toDateTime(unixTimestamp){
                const dateObject = new Date(unixTimestamp);

                const year = dateObject.getFullYear();
                const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Tambah 1 karena bulan dimulai dari 0
                const day = String(dateObject.getDate()).padStart(2, '0');

                const formattedDate = `${year}/${month}/${day}`;

                return formattedDate
            }
        },
    };
</script>

<style scoped>
    .nav-link {
        cursor: pointer !important;
    }

    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }
</style>