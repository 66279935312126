<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div
                                    class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Setting Webhook</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4" v-for="(d, idx) in device" v-bind:key="d.id">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <h3>{{ d.name }}
                                <span v-if="d.status == 'connected'" class="badge badge-success">Terkoneksi</span>
                                <span v-else class="badge badge-danger">Tidak Terkoneksi</span>
                            </h3>
                            <hr style="margin-top: 15px;margin-bottom: 15px;">
                            <button class="btn btn-primary btn-sm" v-on:click="addWebhook(idx)">Tambah URL</button>
                            <br>
                            <div v-for="(link, index) in d.webhook" v-bind:key="index">
                                <br>
                                <label>URL Webhook
                                    <br>
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="d.webhook[index]" placeholder="https://example.com"
                                            class="form-control">
                                        <div class="input-group-append">
                                            <a v-on:click="deleteWebhook(idx, index)"
                                                class="btn btn-outline-danger btn-block">Hapus</a>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <br>
                            <button class="btn btn-success btn-block" v-on:click="saveWebhook(idx)">Simpan</button>
                            <hr style="margin-top: 15px;margin-bottom: 15px;">
                            <button class="btn btn-primary btn-sm" v-on:click="addWebhook2(idx)">Tambah URL 2</button>

                            <br>
                            <div v-for="(link, index) in d.webhook2" v-bind:key="index">
                                <br>
                                <label>URL Webhook
                                    <br>
                                    <div class="input-group mb-3">
                                        <input type="text" v-model="d.webhook2[index]" placeholder="https://example.com"
                                            class="form-control">
                                        <div class="input-group-append">
                                            <a v-on:click="deleteWebhook2(idx, index)"
                                                class="btn btn-outline-danger btn-block">Hapus</a>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <br>
                            <button class="btn btn-success btn-block" v-on:click="saveWebhook2(idx)">Simpan</button>
                            <hr style="margin-top: 15px;margin-bottom: 15px;">

                            <div v-if="d.webhook_media_quota > 0">
                                <button class="btn btn-primary btn-sm" v-on:click="addWebhook3(idx)">Tambah URL
                                    Image</button>

                                <br>
                                <div v-for="(link, index) in d.webhook3" v-bind:key="index">
                                    <br>
                                    <label>URL Webhook
                                        <br>

                                        <div class="input-group mb-3">
                                            <input type="text" v-model="d.webhook3[index]"
                                                placeholder="https://example.com" class="form-control">
                                            <div class="input-group-append">
                                                <a v-on:click="deleteWebhook3(idx, index)"
                                                    class="btn btn-outline-danger btn-block">Hapus</a>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <br>
                                <button class="btn btn-success btn-block" v-on:click="saveWebhook3(idx)">Simpan</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            device: {}
        };
    },
    mounted() {
        this.getDevice()
    },
    methods: {
        async getDevice() {
            let res = await this.$store.dispatch("device_list_all");
            this.device = res.data.data.devices

            for (let i = 0; i < this.device.length; i++) {
                this.device[i].webhook = this.device[i].webhook ? JSON.parse(this.device[i].webhook) : []
                this.device[i].webhook2 = this.device[i].webhook2 ? JSON.parse(this.device[i].webhook2) : []
                this.device[i].webhook3 = this.device[i].webhook3 ? JSON.parse(this.device[i].webhook3) : []
            }
        },
        addWebhook(index) {
            this.device[index].webhook.push("")
        },
        deleteWebhook(idx, index) {
            this.device[idx].webhook.splice(index, 1)
        },
        async saveWebhook(index) {
            try {
                let response = await this.$store.dispatch('device_update_webhook', {
                    id: this.device[index].id,
                    webhook: this.device[index].webhook
                })
                this.$toast.success(response.data.message);
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
        addWebhook2(index) {
            this.device[index].webhook2.push("")
            this.$forceUpdate()
        },
        deleteWebhook2(idx, index) {
            this.device[idx].webhook2.splice(index, 1)
        },
        async saveWebhook2(index) {
            try {
                let response = await this.$store.dispatch('device_update_webhook2', {
                    id: this.device[index].id,
                    webhook: this.device[index].webhook2
                })
                this.$toast.success(response.data.message);
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
        addWebhook3(index) {
            this.device[index].webhook3.push("")
        },
        deleteWebhook3(idx, index) {
            this.device[idx].webhook3.splice(index, 1)
        },
        async saveWebhook3(index) {
            try {
                let response = await this.$store.dispatch('device_update_webhook3', {
                    id: this.device[index].id,
                    webhook: this.device[index].webhook3
                })
                this.$toast.success(response.data.message);
            } catch (err) {
                this.$toast.error(err.response.data.message);
            }
        },
    },
};
</script>
